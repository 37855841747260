
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { useRouter } from 'next/router';
import { Typography, Button } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
export default function Error404() {
    const router = useRouter();
    const { t } = useTranslation('common');
    return (<div style={{ margin: '0 auto', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        {t('404-title')}
      </Typography>
      <Typography variant="body1">{t('404-msg')}</Typography>
      <br />
      <Button onClick={(e) => {
            e.preventDefault();
            router.push('/');
        }} href="/" size="large" color="primary" variant="contained" disableElevation>
        {t('404-back')}
      </Button>
    </div>);
}

    async function __Next_Translate__getStaticProps__19505d72d06__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19505d72d06__ as getStaticProps }
  